import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

const PageTracker = () => {
    const location = useLocation();

    useEffect(() => {
        ReactGA.send('pageview');
    }, [location]);

    return null;
};

export default PageTracker;
