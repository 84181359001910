import React from 'react';
import './HomePage.css';
import MenuHome from './components/MenuHome';
import { useNavigate } from 'react-router-dom';
import Squares from './components/Squares';
import wheel from './img/racing.png';
import arrow from './img/arrow-full.png';

function HomePage() {
  const navigate = useNavigate();

  return (
    <div className="home-container">
      <MenuHome />
      <Squares/>
      <div className="header-section">
        <img src={wheel} alt="Wheel logo" className="wheel"/>
        <div className="main-title">TM Champions</div>
        <div className="second-title">Add your own competition</div>
        <img src={arrow} alt="Arrow" className="arrow"/>
      </div>
      <div className="content-section">
        
        <div className="main-content">

          <p className="site-description">
            TM Champions is dedicated to hosting and displaying leaderboards for community-driven competitions in Trackmania, such as FullSpeed of the Week (FSOTW). Future updates will include diverse rankings for challenges like completing difficult RPG, FS tracks, and more.
          </p>
          <div className="under-content">
            <h2 className="subtitle">Work in Progress</h2>
            <h2>Competitions</h2>
            <div className="competition-links" onClick={() => navigate('/fsotw')}>
              <h3>FullSpeed of the Week (FSOTW)</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
