import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Discover.css';

const Discover = () => {
    const navigate = useNavigate();
    const [currentIndex, setCurrentIndex] = useState(0);
    const items = [
        { name: 'FSOTW', path: '/fsotw' },
        { name: 'HOME', path: '/' }
    ];

    // Change l'item actuel toutes les 5 secondes
    useEffect(() => {
        const intervalId = setInterval(() => {
            slideTo((currentIndex + 1) % items.length);
        }, 5000);  // 5000 ms = 5 seconds

        return () => clearInterval(intervalId);  // Nettoie l'intervalle à la désinscription du composant
    }, [currentIndex, items.length]);

    const slideTo = (index) => {
        setCurrentIndex(index);
    };

    const handleItemClick = (path) => {
        navigate(path);
    };

    return (
        <div className="discover-container">
            <h2>Discover</h2>
            <div className="carousel-container">
                <button onClick={() => slideTo((currentIndex + items.length - 1) % items.length)}>&lt;</button>
                <div className="carousel-item" onClick={() => handleItemClick(items[currentIndex].path)}>
                    <h3>{items[currentIndex].name}</h3>
                </div>
                <button onClick={() => slideTo((currentIndex + 1) % items.length)}>&gt;</button>
            </div>
        </div>
    );
};

export default Discover;
