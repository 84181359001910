import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './LoginAdmin.css';

const LoginAdmin = ({ onLogin }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate(); // Hook for navigation

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Form submitted', { username, password });
        const formData = new URLSearchParams();
        formData.append('username', username);
        formData.append('password', password);
    
        try {
            console.log('In the try');
            const response = await fetch('https://tm-champions.com/loginAdmin.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: formData
            });
    
            const data = await response.json(); // Parsing JSON response
            if (data.message === "Login successful") {
                onLogin(true);
                navigate('/admin');
            } else if (data.error) {
                setError(data.error);
            }
        } catch (error) {
            setError('Login failed. Please try again later.');
            console.error('Error logging in:', error);
        }
    };
    

    return (
        <div className="login-admin">
            <h1>Admin Login</h1>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    placeholder="Username"
                    value={username}
                    onChange={e => setUsername(e.target.value)}
                />
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                />
                {error && <p className="error">{error}</p>}
                <button type="submit">Login</button>
            </form>
        </div>
    );
};

export default LoginAdmin;
