import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import ReactGA  from 'react-ga4';
import HomePage from './HomePage';
import Login from './LoginPage';
import Profile from './ProfilePage';
import FSOTW from './comp/FSOTW';
import LoginAdmin from './admin/LoginAdmin';
import AdminPanel from './admin/AdminPanel';
import Player from './comp/Player';
import PageTracker from './components/PageTracker'

function App() {
    const [isAdmin, setIsAdmin] = useState(false);

    const TRACKING_ID = 'G-Y47ZSFVT27';
    ReactGA.initialize(TRACKING_ID);

    useEffect(() => {
        const storedAdminStatus = localStorage.getItem('isAdmin') === 'true';
        setIsAdmin(storedAdminStatus);
    }, []);

    const handleAdminLogin = (adminStatus) => {
        setIsAdmin(adminStatus);
        localStorage.setItem('isAdmin', adminStatus); 
    };

    return (
        <Router>
            <PageTracker />
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/fsotw" element={<FSOTW />} />
                <Route path="/loginadmin" element={<LoginAdmin onLogin={handleAdminLogin} />} />
                <Route path="/admin" element={isAdmin ? <AdminPanel /> : <Navigate to="/loginadmin" />} />
                <Route path="/login" element={<Login />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/player/:participantId" element={<Player />} />
            </Routes>
        </Router>
    );
}

export default App;
