import React from 'react';

function LoginPage() {
    const handleLogin = () => {
        // Necessary parameters for the OAuth request
        const clientId = '27fd4309544b818f3c67';  // Replace with your actual client ID
        const redirectUri = encodeURIComponent('https://tm-champions.com/profile');
        const responseType = 'code';
        const scope = 'clubs read_favorite write_favorite';  // Adjust scopes as necessary
        const state = generateRandomString();  // Function to generate a random string

        // Construct the authorization URL
        const authUrl = `https://api.trackmania.com/oauth/authorize?response_type=${responseType}&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${encodeURIComponent(scope)}&state=${state}`;

        // Redirect to the authorization URL
        window.location.href = authUrl;
    };

    // Function to generate a random string for the state parameter
    function generateRandomString() {
        return [...Array(30)].map(() => Math.random().toString(36)[2]).join('');
    }

    return (
        <div>
            <h2>Login</h2>
            <button onClick={handleLogin}>Connect with Ubisoft Connect</button>
        </div>
    );
}

export default LoginPage;

  