import React, { useEffect, useState } from 'react';

function ProfilePage() {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    if (code) {
      fetchProfile(code, 'https://tm-champions.com/profile');
    } else {
      setLoading(false);
      setError('Authorization code not available.');
    }
  }, []);

  const fetchProfile = async (code, redirectUri) => {
    try {
      const response = await fetch('https://tm-champions.com/authenticate.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ code, redirect_uri: redirectUri })
      });
      const data = await response.json();
      if (data.success) {
        setProfile(data.data);
      } else {
        throw new Error(data.error || 'Unknown error');
      }
    } catch (error) {
      setError('Failed to fetch profile: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;
  if (!profile) return <p>No profile data available.</p>;

  

  return (
    <div>
      <h1>Welcome, {profile.username || "User"} !</h1>
      {profile.email && <p>Email: {profile.email}</p>}
      {profile.displayName && <p>Display Name: {profile.displayName}</p>}
      <p>Your access token: {profile.access_token}</p> {/* Be cautious about displaying sensitive information like tokens in production */}
    </div>
  );
}

export default ProfilePage;
