import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import MenuHome from '../components/MenuHome';
import Spinner from '../components/Spinner';
import './Player.css';
import Discover from '../components/Discover';
import Squares from '../components/Squares';
import wheel from '../img/racing.png';

const Player = () => {
    const navigate = useNavigate();
    const { participantId } = useParams();
    console.log(participantId);  // Debugging to check participant ID
    const [playerData, setPlayerData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchPlayerData = async () => {
            setLoading(true);
            setError('');
            try {
                const response = await fetch(`https://tm-champions.com/getPlayerData.php?playerId=${participantId}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                if (data.success) {
                    setPlayerData(data.player);
                } else {
                    console.error('Failed to fetch player data:', data.message);
                    setError('Failed to fetch player data.');
                }
            } catch (error) {
                console.error('Error fetching player data:', error);
                setError('Error fetching player data.');
            } finally {
                setLoading(false);
            }
        };

        fetchPlayerData();
    }, [participantId]);

    const handleImageClick = () => {
        navigate('/');
    };

    return (
        <div className="player-container">
            <Squares />
            <div className="title-container"><img src={wheel} alt="Wheel logo" className="wheel-title" onClick={handleImageClick}/><h1>FullSpeed Of The Week</h1></div>
            <MenuHome />
            <div className="profile-container">
            <Discover />
                {loading ? (
                    <Spinner />
                ) : playerData ? (
                    <>
                        <h1>{playerData.username}</h1>
                        <ul>
                            {playerData.competitions ? (
                                playerData.competitions.length > 0 ? (
                                    playerData.competitions.map(comp => (
                                        <li key={comp.compId}>
                                            {comp.channel_name}: Ranked {comp.rank} out of {comp.totalParticipants}
                                        </li>
                                    ))
                                ) : (
                                    <p>No competitions data available.</p>
                                )
                            ) : (
                                <p>No competition data available.</p>
                            )}
                        </ul>
                    </>
                ) : (
                    <p>{error || "No player data available."}</p>
                )}
            </div>
        </div>
    );
};

export default Player;
