import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import './FSOTW.css';
import trophyBlack from '../img/trophy-fill-black.png';
import trophyOrange from '../img/trophy-fill-orange.png';
import searchIcon from '../img/search-icon.png';
import Discover from '../components/Discover';
import LatestWeek from '../components/LatestWeek';
import Squares from '../components/Squares';
import wheel from '../img/racing.png';
import MenuHome from '../components/MenuHome';


const FSOTW = () => {
    const navigate = useNavigate();
    const [competitions, setCompetitions] = useState([]);
    const [selectedCompetition, setSelectedCompetition] = useState('overall');
    const [selectedLabel, setSelectedLabel] = useState('S4 Overall');
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [showFullList, setShowFullList] = useState(false);
    const [latestTopThree, setLatestTopThree] = useState([]);
    const [mapData, setMapData] = useState(null);

    useEffect(() => {
        const fetchCompetitions = async () => {
            const url = `https://tm-champions.com/getCompetitions.php?compId=${selectedCompetition}`;
            try {
                const response = await fetch(url);
                const data = await response.json();
                if (Array.isArray(data)) {
                    const parsedCompetitions = data.map(competition => {
                        const participants = JSON.parse(competition.participants_json || '[]').sort((a, b) => a.rank - b.rank);
                        // Assign ranks before filtering
                        participants.forEach((participant, index) => participant.originalRank = index + 1);
                        return {
                            ...competition,
                            participants
                        };
                    });
                    setCompetitions(parsedCompetitions);
                } else {
                    throw new Error("Data is not an array");
                }
            } catch (error) {
                console.error('Failed to fetch competitions:', error);
            }
        };
        fetchCompetitions();
        fetchLatestWeek('LID-COMP-znrr2ot0semxepr');
    }, [selectedCompetition]);

    const fetchLatestWeek = async (latestWeekId) => {
        const url = `https://tm-champions.com/getCompetitions.php?compId=${latestWeekId}`;
        try {
            const response = await fetch(url);
            const data = await response.json();
            if (Array.isArray(data) && data.length > 0) {
                const participants = JSON.parse(data[0].participants_json || '[]').sort((a, b) => a.rank - b.rank);
                setLatestTopThree(participants.slice(0, 3)); // Enregistrement du top 3 dans l'état
            } else {
                throw new Error("Data is not an array or is empty");
            }
        } catch (error) {
            console.error('Failed to fetch latest week data:', error);
        }
    };

    const handleSelection = (compId, label) => {
        setSelectedCompetition(compId);
        setSelectedLabel(label);
    };

    const handleTrophyHover = (e, isInTopThree) => {
        const trophyImgContainer = e.currentTarget.querySelector('.trophy');
        if (trophyImgContainer) {
            const trophyImg = trophyImgContainer.querySelector('img');
            const trophyNumber = trophyImgContainer.querySelector('.trophy-number');
    
            if (trophyImg && trophyNumber) {
                trophyImg.src = e.type === 'mouseenter' ? (isInTopThree ? trophyOrange : trophyBlack) : (isInTopThree ? trophyBlack : trophyOrange);
                trophyNumber.style.color = e.type === 'mouseenter' ? (isInTopThree ? 'black' : '#ec7b6a') : (isInTopThree ? '#ec7b6a' : 'black');
            }
        }
    };

    const toggleShowMore = () => {
        setShowFullList(!showFullList);
    };
    

    const fetchMapData = useCallback(async (mapId) => {
        try {
            const response = await fetch(`https://tm-champions.com/getMap.php?mapId=${mapId}`);
            const data = await response.json();
            if (data.success) {
                const mapInfo = {
                    ...data.mapData,
                };
                setMapData(mapInfo);
            } else {
                console.error('Map data fetch error:', data.message);
            }
        } catch (error) {
            console.error('Failed to fetch map data:', error);
        }
    }, []);

    useEffect(() => {
        fetchMapData('16a8e88e-c9f6-4d71-b7f7-006322f11ec3');
    }, [fetchMapData]);

    const handleImageClick = () => {
        navigate('/');
    };

    const handlePlayerClick = participantId => {
        console.log('Clicked participant ID:', participantId);
        if (!participantId) {
            console.error('Participant ID is missing');
            return;
        }
        navigate(`/player/${participantId}`);
    };

    return (
        <div className="leaderboard">
            <Squares />
            <div className="title-container"><img src={wheel} alt="Wheel logo" className="wheel-title" onClick={handleImageClick}/><h1>FullSpeed Of The Week</h1></div>

            <div className="controls">
                <div className="custom-dropdown" onMouseEnter={() => setDropdownOpen(true)} onMouseLeave={() => setDropdownOpen(false)}>
                    <h2>{selectedLabel} <span className="caret">&#9660;</span></h2>
                    {dropdownOpen && (
                        <div className="dropdown-content">
                            <div onClick={() => handleSelection('overall', 'S4 Overall')}>S4 Overall</div>
                            <div onClick={() => handleSelection('LID-COMP-gsdv3e5qyhbqori', 'Week 1')}>Week 1</div>
                            <div onClick={() => handleSelection('LID-COMP-m1mbm0s3k0ixeoc', 'Week 2')}>Week 2</div>
                            <div onClick={() => handleSelection('LID-COMP-znrr2ot0semxepr', 'Week 3')}>Week 3</div>
                        </div>
                    )}
                </div>
                <div className="search-container">
                    <input
                        type="text"
                        placeholder="Search"
                        className="search-input"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value.toLowerCase())}
                    />
                    <img src={searchIcon} alt="Search" className="search-icon"/>
                </div>
            </div>

            <div className="leaderboard-container">
                <MenuHome/>
                    <Discover />
                        {competitions.map((competition, index) => (
                            <div key={index} className="competition">
                                {competition.participants
                                    .filter(participant => participant.username.toLowerCase().includes(searchQuery))
                                    .slice(0, showFullList || window.innerWidth > 768 ? competition.participants.length : 10)
                                    .map((participant, idx) => (
                                        <div key={idx} className={`participant ${idx < 3 ? `top-three top-${idx + 1}` : ''}`}
                                            onMouseEnter={(e) => handleTrophyHover(e, idx < 3)}
                                            onMouseLeave={(e) => handleTrophyHover(e, idx < 3)}
                                            onClick={() => handlePlayerClick(participant.participant)} // Utilise ici l'ID du joueur
                                            >
                                            <span className="rank">{participant.originalRank}</span>
                                            <span className="name">{participant.username}</span>
                                            {participant.nb_wins_fsotw4 > 0 && (
                                                <div className="trophy">
                                                    <img src={idx < 3 ? trophyBlack : trophyOrange} alt="Trophy" className="trophy-img"/>
                                                    <span className={`trophy-number ${idx < 3 ? '' : 'outside-top-three'}`}>{participant.nb_wins_fsotw4}</span>
                                                </div>
                                            )}
                                            <span className="score">{participant.score}</span>
                                        </div>
                                    ))}
                                {competition.participants.length > 15 && window.innerWidth <= 768 && (
                                    <button onClick={toggleShowMore} className="show-more">
                                        {showFullList ? 'SHOW LESS' : 'SHOW MORE'}
                                    </button>
                                )}
                            </div>
                        ))}

                        <LatestWeek
                            latestTopThree={latestTopThree}
                            mapData={mapData}
                        />

            </div>
        </div>
    );
};

export default FSOTW;