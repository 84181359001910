import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './AdminPanel.css';

const AdminPanel = () => {
    const [competitionId, setCompetitionId] = useState('');
    const [competitions, setCompetitions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [mapId, setMapId] = useState('');
    const navigate = useNavigate(); // Hook for navigation

    useEffect(() => {
        fetchCompetitions();
    }, []);

    const fetchCompetitions = async () => {
        try {
            const response = await fetch('https://tm-champions.com/getCompetitions.php');
            const data = await response.json();
            setCompetitions(data);
        } catch (error) {
            console.error('Error fetching competitions:', error);
            setMessage('Error fetching competitions data');
        }
    };

    const handleAddCompetition = async (e) => {
        e.preventDefault();
        setLoading(true);
        setMessage('');
        
        const formData = new URLSearchParams();
        formData.append('compId', competitionId.trim());

        try {
            const response = await fetch('https://tm-champions.com/fetchCompetitionDetails.php', {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: formData.toString()
            });
            const data = await response.json();
            if (data.success) {
                setMessage('Competition added successfully!');
                fetchCompetitions();
            } else {
                setMessage('Error adding competition: ' + data.error);
            }
        } catch (error) {
            setMessage('Failed to add competition: ' + error.message);
        } finally {
            setLoading(false);
            setCompetitionId('');
        }
    };

    const fetchMapData = async () => {
        setLoading(true);
        setMessage('');
    
        const formData = new URLSearchParams();
        formData.append('mapId', mapId);
    
        try {
            const response = await fetch('https://tm-champions.com/getMapInfos.php', {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: formData.toString()
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            if (data.success) {
                console.log("Map data fetched and inserted:", data.mapInfo);
            } else {
                console.error("Server responded with an error:", data.error);
            }
            setMessage(data.message || 'Map data fetched successfully!');
        } catch (error) {
            console.error('Failed to fetch map data:', error);
            setMessage(`Failed to fetch map data: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    const handleNavigateToLogin = () => {
        navigate('/login'); // Navigate to login page
    };
    

    return (
        <div className="admin-panel">
            <h1>Panel Administrateur</h1>
            <form onSubmit={handleAddCompetition}>
                <input type="text" placeholder="Enter Competition ID" value={competitionId} onChange={e => setCompetitionId(e.target.value)} />
                <button type="submit" disabled={loading}>Add Competition</button>
            </form>

            <input type="text" placeholder="Enter Map ID" value={mapId} onChange={e => setMapId(e.target.value)} />
            <button onClick={fetchMapData} disabled={loading}>Fetch Map Data</button>
            <button onClick={handleNavigateToLogin}>Go to Login Page</button>

            {message && <p>{message}</p>}
            {competitions.map(comp => (
                <div key={comp.compId} style={{ border: '1px solid #ccc', margin: '10px', padding: '10px' }}>
                    <h2>Competition ID: {comp.compId}</h2>
                    <pre>{JSON.stringify(comp, null, 2)}</pre>
                </div>
            ))}
        </div>
    );
};

export default AdminPanel;
