import React, { useState } from 'react';
import './MenuHome.css'; // Assurez-vous que le chemin est correct

const MenuHome = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);  // Cette action bascule l'état de isOpen
    };

    return (
        <div className="menu-container-home">
            <div className="menu-icon-home" onClick={toggleMenu}>
                {isOpen ? <span className="menu-close-home">&times;</span> : <span className="menu-burger-home">&#9776;</span>}
            </div>
            
            {/* Contenu du menu */}
            <div className={`menu-home ${isOpen ? 'open' : ''}`}>
                <h2>Menu</h2>
                <a href="/" className="menu-link-home">Home</a>
                <a href="/fsotw" className="menu-link-home">FSOTW</a>
                <a href="/about" className="menu-link-home">About</a>
            </div>
        </div>
    );
};

export default MenuHome;
