import React from 'react';
import './LatestWeek.css';  // Assuming styles specific to this component

const LatestWeek = ({ latestTopThree, mapData }) => {
    // Utilitaire pour convertir le score en millisecondes en temps HH:MM:SS avec millisecondes
    const formatTime = (ms) => {
        let seconds = Math.floor(ms / 1000);
        let minutes = Math.floor(seconds / 60);
        seconds = seconds % 60; // Remaining seconds
        const milliseconds = ms % 1000; // Remaining milliseconds
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}:${milliseconds.toString().padStart(3, '0')}`;
    };

    // Utility to parse TrackMania styled text to HTML
    const parseTrackmaniaText = (text) => {
        let styledText = text.replace(/\$([0-9a-fA-F]{3})/g, '<span style="color: #$1;">').replace(/\$z/g, '</span>').replace(/\$w/g, '<span style="font-weight: bold;">').replace(/\$i/g, '<span style="font-style: italic;">').replace(/\$s/g, '<span style="text-decoration: underline;">').replace(/\$o/g, '<span style="text-decoration: overline;">').replace(/\$n/g, '<span style="font-weight: normal;">').replace(/\$g/g, '</span>');
        return styledText;
    };

    return (
        <div className="latest-week">
            <h2>Latest Week</h2>
            <h3>Top 3</h3>
            {latestTopThree.length > 0 ? (
                <div className="participant-week-container">
                    {latestTopThree.map((participant, index) => (
                        <div key={index} className={"participant-week"}>
                            <span className="rank-week">{index + 1}</span>
                            <span className="name-week">{participant.username}</span>
                            <span className="score-week">{participant.score}</span>
                        </div>
                    ))}
                </div>
            ) : (
                <p>No data available for the latest week.</p>
            )}
            <h3>Map Information</h3>
            {mapData ? (
                <div className="map-details">
                    <img src={mapData.thumbnailUrl} alt="Map Thumbnail" className="map-thumbnail" />
                    <h4 className="map-name" dangerouslySetInnerHTML={{ __html: parseTrackmaniaText(mapData.name) }}></h4>
                    <p className="map-author">by {mapData.authorConverted}</p>
                    <p className="wr-time">WR: {mapData.wr_time}</p>
                    <div className="medal-info">
                        <div className="medal-icon gold-medal"></div>
                        <p>Gold: {formatTime(mapData.goldScore)}</p>
                    </div>
                    <div className="medal-info">
                        <div className="medal-icon silver-medal"></div>
                        <p>Silver: {formatTime(mapData.silverScore)}</p>
                    </div>
                    <div className="medal-info">
                        <div className="medal-icon bronze-medal"></div>
                        <p>Bronze: {formatTime(mapData.bronzeScore)}</p>
                    </div>
                    <a href={mapData.fileUrl} className="download-button" target="_blank" rel="noopener noreferrer">Download Map</a>
                </div>
            ) : (
                <p>No map data available</p>
            )}
        </div>
    );
};

export default LatestWeek;
