import React from 'react';
import './Squares.css';

function Squares() {

  return (
    <div className="body-squares">
        <div className="square square1"></div>
        <div className="square square2"></div>
        <div className="square square3"></div>
        <div className="square square4"></div>
        
    </div>
  );
}

export default Squares;
